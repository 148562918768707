import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { SlideMenu } from "primereact/slidemenu";
import { Button } from "primereact/button";
import userSessionStorage from "../../services/userSessionStorage";
// import TreeButton from "../bookSki/tree/TreeButton";

import "primeicons/primeicons.css";
import "./AppMenu.css";

const AppMenu = (props) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  
  const menu = useRef(null);
  const [myMenu, setMyMenu] = useState([
    {
      key: 0,
      label: "",
      icon: "",
      items: [],
      url: "",
      localize: "",
    },
  ]);
 
  //   const windowSize = useRef([window.innerWidth, window.innerHeight]);
  //const location = useLocation();

  // const copyTooltipRef = useRef();
  //   useEffect(() => {
  //     copyTooltipRef &&
  //       copyTooltipRef.current &&
  //       copyTooltipRef.current.updateTargetEvents();
  //   }, [location]);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setIsMobile(window.innerWidth <= 1000);
    });
  }, [isMobile]);

  useEffect(() => {
    setMyMenu(ObjectWithItemsProperty(props?.model));
  }, [props?.model]);

  const nodeTemplate = (node, options) => {
    let label = <b>{node.label}</b>;

    if (node.url) {
      label = (
        <a
          href={node.url}
          className="text-primary hover:underline font-semibold"
        >
          {node.label}
        </a>
      );
    }

    return <span className={options.className}>{label}</span>;
  };

  const findDeepChildren = (array, targetChild) => {
    if (!Array.isArray(array)) {
      return array;
    }
    for (const item of array) {
      const { children } = item;
      //console.log(children);
      if (children) {
        //console.log(item);
        return item;
      }

      if (Array.isArray(item)) {
        const foundChild = findDeepChildren(item, targetChild);
        if (foundChild) {
          //console.log(foundChild);
          return foundChild;
        }
      }
    }

    return null;
  };

  const ObjectWithItemsProperty = (array) => {
    // console.log(array);
    if (!Array.isArray(array)) {
      return array;
    }
    const transformedArray = array?.map((myObject) => {
      return {
        ...myObject,
        items: findDeepChildren(myObject.children, "children")
          ? ObjectWithItemsProperty(myObject?.children) // findDeepChildren(myObject.children, "children")?.children
          : myObject.children,
        children: [],
      };
    });

    return transformedArray || []; //( JSON.stringify(transformedArray));
  };

  const handleDefaultBehavior = (e) => {
    e.preventDefault();
    let href = e?.target?.href;
    if (!href) {
      href = e?.target?.parentElement?.href;
    }
   
    if (href && !String(href)?.includes('#')) {
      menu.current.toggle(e);
      const url = String(href).replace(process.env.REACT_APP_ENV_VARIABLE,"");
      navigate(`/${url}`);
    }
  };

  return (
    <>
      {/* {console.log(myMenu)} */}
      {/* {console.log(props)}
       <div className="layout-topbar">
        <div className="layout-topbar-left">
          <button
            className="topbar-menu-button p-link"
            onClick={props.onMenuButtonClick}
          >
            <i className="pi pi-bars"></i>
          </button>

          <button className="logo p-link" onClick={() => navigate("/")}>
            <img
              src={`assets/layout/images/logo-${
                props.colorScheme === "light" ? "dark" : "light"
              }.png`}
              alt="logo"
            />
          </button>

          <button className="p-link" onClick={() => navigate("/")}>
            <img
              src={`assets/layout/images/appname-${
                props.colorScheme === "light" ? "dark" : "light"
              }.png`}
              className="app-name"
              alt="app-name"
            />
          </button>
        </div>
      </div>  

      {isMobile && <TreeButton className="burger-menu" />} */}
      {isMobile && (
        <div className="card flex justify-content-center">
          {/* {console.log(props)} */}
          <SlideMenu
            ref={menu}
            model={myMenu}
            onClickCapture={handleDefaultBehavior}
            popup
            viewportHeight={370}
            className="slide-menu"
            menuWidth={330}
          ></SlideMenu>
          {/* <TreeButton className="burger-menu" /> */}
          <Button
            type="button"
            icon="pi pi-bars"
            className="slide-menu-button p-button-outlined p-button-primary"
            onClick={(event) => {
              menu.current.toggle(event);
            }}
          ></Button>
          <div className="slide-menu-button-language-selector">
            <div hidden={userSessionStorage.getLocalize() == "bg"} >
              <Button
                label="БГ"
                className="slide-menu-overlay-button-languge-selector"
                onClick={(event) => {
                  if (sessionStorage.getItem("localize") != "bg") {
                    sessionStorage.setItem("localize", "bg");
                    // setMyMenu({ ...myMenu, localize: "bg" });
                    props.changeLanguageClick(true);
                  }
                }}
              />
            </div>
            <div
              hidden={
                userSessionStorage.getLocalize() == "en"
              } 
            >
              <Button
                label="EN"
                className="slide-menu-overlay-button-languge-selector"
                onClick={(event) => {
                  if (sessionStorage.getItem("localize") != "en") {
                    sessionStorage.setItem("localize", "en");
                    // setMyMenu({ ...myMenu, localize: "en" });
                    props.changeLanguageClick(true);
                  }
                }}
              />
            </div>
          </div>
          <div className="slide-menu-button-label-signs">
            <div hidden={!userSessionStorage.hasToken()}>
              <Link to={"/logout"}>
                <Button
                  label="ИЗХОД"
                  className="slide-menu-overlay-button-label-signs"
                />
              </Link>
            </div>
            <div hidden={userSessionStorage.hasToken()}>
              <Link to={"/login"}>
                <Button
                  label="ВХОД"
                  className="slide-menu-overlay-button-label-signs"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppMenu;
