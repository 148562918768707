import React from 'react';

const TimeCounter = ({ video, styles }) => {
  const secondsToHms = (seconds) => {
    seconds = Number(seconds);
    if (!seconds) return '00:00:00';

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 3600 % 60);

    return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`;
  };

  return (
    <div style={styles?.time}>
      {secondsToHms(video.currentTime)} / {secondsToHms(video.duration)}
    </div>
  );
};

export default TimeCounter;