import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import userSessionStorage from "../../../services/userSessionStorage";
import { AuthContext } from "../../../context/AuthContext";

//({ path, permission, children, ...others })
const ProtectedRoute = ({ children, role, uab }) => {
  const context = useContext(AuthContext);
  const isAuthenticated = context.isAuthenticated();
  const location = useLocation();
  const hasUserBookAccess = location.pathname.length > 1 ? String(context.userBookAccess()).includes(location.pathname?.split('/')[1]):false;

  useEffect(() => {
    if (!isAuthenticated) {
      userSessionStorage.clearSession();
    }
  }, [isAuthenticated]);

  const grantedPermision = () => {
    return (context.role() === role) ? children : <Navigate to="/access" />;
  };
  
  const userHasPermision = () => {
    return (hasUserBookAccess) ? children : <Navigate to="/access" />;
  }

  return isAuthenticated ? (
    role ? (
      grantedPermision()
    ) : (
      // children
      userHasPermision()
    )
  ) : (
    <Navigate to={`/login/?go=${location.pathname}`} />
  );
};

export default ProtectedRoute;
