/* eslint-disable import/no-anonymous-default-export */
import { Books, UserLanguages } from "../common/constants";

import jwtDecode from "jwt-decode";

const env = process.env;

function getUserLocalize() {
  let props = sessionStorage.getItem("pr");
  if (props && props.length > 6) {
    const localizeType = props?.split("ea1cl")[1];
    if (localizeType) {
      return UserLanguages.find((u) => u.value == localizeType)?.localize;
    }
  }

  return 0;
}

function getRole() {
  let props = sessionStorage.getItem("pr");
  if (props && props.length > 6) {
    return props?.split("y")[2];
  }

  return 0;
}

function getClass() {
  let props = sessionStorage.getItem("pr");
  if (props && props.length > 6) {
    return props?.split("y")[3];
  }

  return null;
}

function getAccessBook() {
  let props = sessionStorage.getItem("pr");
  if (props && props.length > 6) {
    const access = props?.split("y")[4]?.substring(0, 1);
    if (access) {
      return Books.find((b) => b.session == access)?.value;
    }
  }

  return null;
}

function getSelectedTable() {
  return sessionStorage.getItem("tbl");
}

function getToken() {
  const token = sessionStorage.getItem("token");

  if (token != null) {
    const decoded = jwtDecode(token);
    // "aud": "",
    // "auth_time": 0,
    // "user_id": "",
    // "sub": "",
    // "iat": 0,
    // "exp": 0,
    // "email": "",
    // "email_verified": false,

    //console.log(decoded);
    const isTokenNotExp = decoded?.exp - Date.now() / 1000 > 0;

    return decoded?.aud === env.REACT_APP_PROJECT_ID && isTokenNotExp;
  }

  return false;
}

function hasToken() {
  if (sessionStorage.getItem("token")) return true;
  return false;
}

function getLocalize() {
  return sessionStorage.getItem("localize");
}

function setToken(token) {
  sessionStorage.setItem("token", token);
}

function setDefaultLanguage() {
  return sessionStorage.setItem("localize", "bg");
}

function setDefaultAdministratioinLanguage() {
  return sessionStorage.setItem("localize", "ff");
}

function clearSession(){
  sessionStorage.removeItem("tbl");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("pr");
}

export default {
  getToken,
  setToken,
  hasToken,
  getRole,
  getClass,
  getLocalize,
  setDefaultLanguage,
  setDefaultAdministratioinLanguage,
  getAccessBook,
  getSelectedTable,
  getUserLocalize,
  clearSession,
};
