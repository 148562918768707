import React, { useContext, useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

import { AuthContext, authValues } from "../../../context/AuthContext";
//import { Link } from "react-router-dom";

import "./Login.css";
function Login(props) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const [pathname, setPathname] = useState(
    String(location.search).split("=")[1]
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const authenticationContext = useContext(AuthContext);

  useEffect(() => {
    watch(() => {
      setError("");
    });
  }, [watch]);
  const toast = useRef(null);

  const showError = () => {
    toast.current.show({
      severity: "error",
      summary: "login failed / Неуспешно влизане",
      detail:
        "check your email and/or password / Проверете е.поща и/или парола",
      //   life: 3000,
      // sticky: true,
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    await authenticationContext
      .login(data)
      .then((res) => {
        //window.location.reload();
        //window.location.replace("/");
        if (!authenticationContext.isAuthenticated()) {
          showError();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (pathname) {
          props.onLoginSubmitClick({ changeLocalize: true });
          setTimeout(() => {
            navigate(pathname);
          }, 1000);
        } else {
          props.onLoginSubmitClick({ changeLocalize: true });
          navigate("/");
        }
      })
      .catch((e) => {
        // console.log(e);
        setError(e);
        setLoading(false);
      });
  };

  return (
    <div className="login-body">
      <div className="login-image">
        <img src={`/images/background-left.png`} alt="ski" />
      </div>{" "}
      <Toast ref={toast} />{" "}
      <div hidden={!loading}>
        <ProgressSpinner
          className="login-progress-spinner"
          style={{
            width: "100px",
            height: "100px",
            position: "fixed",
            padding: "30px",
          }}
          strokeWidth="1"
          fill="var(--surface-ground)"
          animationDuration="1s"
        />
      </div>
      <div className="login-panel p-fluid">
        <div className="flex flex-column">
          <div className="flex align-items-center mb-6 logo-container">
            <img
              src={`/assets/layout/images/logo-${false ? "dark" : "light"}.png`}
              className="login-logo"
              alt="login-logo"
            />
            <img
              src={`/assets/layout/images/appname-${
                false ? "dark" : "light"
              }.png`}
              className="login-appname"
              alt="login-appname"
            />
          </div>{" "}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                width: "300px",
              }}
              className={`service-div`}
            >
              <React.Fragment>
                <h3> {"Вход"}: </h3>{" "}
                {error && <div className="error-message"> {error} </div>}{" "}
                <InputText
                  autoComplete="email"
                  type="email"
                  autoFocus
                  className="mb-4"
                  placeholder={"Email"}
                  disabled={loading}
                  style={
                    errors["email"] && {
                      border: "1px solid red",
                    }
                  }
                  {...register("email", {
                    required: true,
                  })}
                />{" "}
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Password
                      autoComplete="off"
                      disabled={loading}
                      inputClassName={errors["password"] && "p-invalid"}
                      {...field}
                      placeholder={"Password"}
                      feedback={false}
                    />
                  )}
                />{" "}
                <Button
                  className="mt-4"
                  label={"Вход"}
                  disabled={loading}
                  type="submit"
                ></Button>
                {/* <Link to={"/forgot-password"}>
                    <button type="button" className="p-link forget-password">
                      {("ForgotPassword")}?
                    </button>
                  </Link>
                  <p>
                    {("DoNotYouHaveAnAccount")}?{" "}
                    <button type="button" className="p-link">
                      <Link to={"/register"}>{("SignUp")}</Link>
                    </button>
                  </p> */}{" "}
              </React.Fragment>{" "}
            </div>{" "}
          </form>{" "}
          {/* <div className="form-container">
                    <span className="p-input-icon-left">
                      <i className="pi pi-envelope"></i>
                      <InputText value="email" type="text" placeholder="Email" />
                    </span>
                    <span className="p-input-icon-left">
                      <i className="pi pi-key"></i>
                      <InputText
                        value="password"
                        type="password"
                        placeholder="Password"
                      />
                    </span>
                    <button className="flex p-link">Forgot your password?</button>
                  </div> */}{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
}

export default Login;
