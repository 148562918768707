import React, { useState } from "react";

import firebaseService from "../services/firebase";
import userSessionStorage from "../services/userSessionStorage";
import { crypt, decrypt } from "../common/helpers";
import { Roles } from "../common/constants";
export const AuthContext = React.createContext();

function isAuthenticated() {
  return userSessionStorage.getToken();
}

let user = null;
function getLoginUser() {
  return user;
}

async function login(data) {
  let userFromDb = null;
  let f = await firebaseService
    .getAuthUser(data)
    .then((userCredentialImpl) => {
      userSessionStorage.setToken(userCredentialImpl.user.accessToken);
    })
    .then(() => {
      getUserFromDb(data)
        .then((res) => {
          const password = decrypt(res?.hash);
          if (data?.password != password) {
            const changedPassword = crypt(data.password);
            firebaseService
              .setUser({
                ...res,
                hash: changedPassword,
                hasNewUser: false,
              })
              .then(() => {});
          }
          userFromDb = "user";
        })
        .catch(() => {});
      return userFromDb;
    })
    .catch((err) => {
      //console.log(err);
      return "err";
    });
  return f;
}

async function logout() {
  userSessionStorage.clearSession();
  firebaseService.signOutUser();
}

async function getUserFromDb(data) {
  let result = null;
  await firebaseService.getUser(data).then((res) => {
    let pr = "";
    res.forEach((doc) => {
      pr = `y${pr}y${String(doc.data()?.role)}y${doc.data()?.class}y${
        doc.data()?.uab
      }ea1cl${doc.data()?.localize}`;
      sessionStorage.setItem("pr", pr);
      //user = doc.data()?.email;
      result = doc.data();
    });
  });
  return result;
}

function role() {
  if (userSessionStorage.getRole()) {
    let roleId = userSessionStorage.getRole();
    return Roles[roleId];
  }
  return null;
}

function userBookAccess() {
  return userSessionStorage.getAccessBook();
}

function isAdmin() {
  return role() === Roles[1];
}

export const authValues = {
  isAuthenticated,
  userBookAccess,
  getLoginUser,
  isAdmin,
  role,
  login,
  logout,
};
