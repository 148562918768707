export const youtubeConfig = ({
  width,
  height,
  end
}) => {
  return {
    embedOptions: {
      width: width,
      height: height,
      playsinline: 0,
      controls: 0,
      showinfo: 0,
      frameborder: 0,
      enablejsapi: 1,
      cc_load_policy: 0,
      iv_load_policy: 3,
      origin: "https://a.bg",
      start: 0,
      end: end,
      fs: 0,
    },
    playerVars: {
      controls: 0,
      showinfo: 0,
      frameborder: 0,
      enablejsapi: 1,
      iv_load_policy: 3,
      cc_load_policy: 0,
      origin: "https://a.bg",
      start: 0,
      end: end,
      fs: 0,
      // width: 640,
      // height: 360,
    },
  }
}