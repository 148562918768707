import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

import { AuthContext, authValues } from "../../context/AuthContext";
//import firebaseService from "../../services/firebase";

import "./Home.css";
import userSessionStorage from "../../services/userSessionStorage";
// import { nodeModel } from "../../common/models";
// import { AuthContext } from "../../context/AuthContext";
// import { useAuth } from "../../hooks/useAuth";

const Home = (props) => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = useState(
    context.isAuthenticated
  );
  const [changeLanguage, setChangeLanguage] = useState(false);
  const [isDesktop, setisDesktop] = useState(window.innerWidth > 1000);
  const [imageSize, setImageSize] = useState({ width: 1, height: 1 });
  const [loading, setLoading] = useState(true);
  const [skiNodes, setSkiNodes] = useState(loadNodes("ski"));
  const [snowboardNodes, setSnowboardNodes] = useState(loadNodes("snowboard"));
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [searchParams] = useSearchParams();
  //const token = searchParams.get("token");

  useEffect(() => {
    //setNodes(props.nodes);
    setSkiNodes(loadNodes("ski"));
    setSnowboardNodes(loadNodes("snowboard"));
    setLoading(false);
  }, [props.nodes]);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setisDesktop(window.innerWidth > 1000);
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    });
  }, [isDesktop, imageSize]);

  useEffect(() => {
    const image = new Image();
    image.src = "./images/Frontpage.jpg";

    image.onload = () => {
      setImageSize({ width: image.width, height: image.height });
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      userSessionStorage.clearSession();
    }
  }, [isAuthenticated]);

  const skiButtonMap = () => {
    let idx = 0;
    return skiNodes.map((button) => {
      return (
        <Button
          key={++idx}
          label={String(button?.label).toUpperCase()}
          className="overlay-button-ski"
          onClick={() =>
            navigate(String(button?.url ?? button.children[0]?.url))
          }
        />
      );
    });
  };

  const snowboardButtonMap = () => {
    let idx = 0;
    return snowboardNodes.map((button) => {
      return (
        <Button
          key={++idx}
          label={String(button?.label).toUpperCase()}
          className="overlay-button-snowboard"
          onClick={() =>
            navigate(String(button?.url ?? button.children[0]?.url))
          }
        />
      );
    });
  };

  function loadNodes(book) {
    return props?.nodes?.filter(
      (f) =>
        f.url?.includes(`/${book}`) ||
        (f.children && f?.children[0]?.url?.includes(`/${book}`))
    );
  }

  const languageSelector = () => {
    return (
      <React.Fragment>
        <div hidden={userSessionStorage.getLocalize() == "bg"}>
          <Button
            label="БГ"
            className="overlay-button-languge-selector"
            onClick={() => {
              if (sessionStorage.getItem("localize") != "bg") {
                sessionStorage.setItem("localize", "bg");
                setChangeLanguage(true);
                props.changeLanguageClick(true);
              }
            }}
          />
        </div>
        <div hidden={userSessionStorage.getLocalize() == "en"}>
          <Button
            label="EN"
            className="overlay-button-languge-selector"
            onClick={() => {
              if (sessionStorage.getItem("localize") != "en") {
                sessionStorage.setItem("localize", "en");
                setChangeLanguage(true);
                props.changeLanguageClick(true);
              }
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const sign = (
    <>
      <div hidden={isAuthenticated}>
        <Link to={"/login"}>
          <Button className="overlay-button-label-signs">ВХОД</Button>
        </Link>
      </div>
      <div hidden={!isAuthenticated}>
        <Link to={"/logout"}>
          <Button className="overlay-button-label-signs">ИЗХОД</Button>
        </Link>
      </div>
    </>
  );

  return (
    <>
      <div className="container" style={{ justifyContent: "center" }}>
        {/* <div className="col-1.5">
        <div className="card">
        <img src="./images/background-left.png" alt="background-left" />
        </div>
    </div> */}
        <div className="col-0">
          <div className="card">
            <img
              className="background-image"
              src="./images/Frontpage.jpg"
              alt="background-home"
              width={
                imageSize.width === 1
                  ? imageSize.width
                  : (imageSize.width * screenSize.width) / imageSize.width
              }
              height={
                imageSize.heigh === 1
                  ? imageSize.heigh
                  : (imageSize.height * screenSize.height) / imageSize.height
              }
            />
            <div hidden={!loading}>
              <ProgressSpinner
                className="home-progress-spinner"
                strokeWidth="1"
                // fill="var(--surface-ground)"
                animationDuration="1.5s"
              />
            </div>
            {isDesktop && (
              <div>
                <div className="button-container-ski">{skiButtonMap()}</div>
                <div className="button-container-snowboard">
                  {snowboardButtonMap()}
                </div>
                <div className="label-signs">{sign}</div>
                <div className="label-language-selector">
                  {languageSelector()}
                </div>
                {/*
                <div className="button-container-footer">
                  <Button
                    label="PEDAGOGY"
                    className="overlay-button-ski"
                    onClick={() => navigate("/pedagogy")}
                  />
                  <Button
                    label="SAFETY"
                    className="overlay-button-ski"
                    onClick={() => navigate("/safety")}
                  />
                  <Button
                    label="AUTHORS"
                    className="overlay-button-ski"
                    onClick={() => navigate("/authors")}
                  />
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
