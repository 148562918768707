import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
//import { Controller, useForm } from 'react-hook-form';
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
// import { FileUpload } from "primereact/fileupload";

//import nodes from "../../common/nodes.json";
import { cardModel, userModel, nodeModel } from "../../common/models";
import {
  Languges,
  Books,
  UserBookAccess,
  SelectRoles,
  //Roles,
  UserLanguages,
} from "../../common/constants";
import firebaseService from "../../services/firebase";
import bookModifierService from "../../services/bookModifier";
import userSessionStorage from "../../services/userSessionStorage";
import {
  isEmailValid,
  crypt,
  // decrypt,
  // exportData
} from "../../common/helpers";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
//import "../../index.css";
import "./Administration.css";

const Administration = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [elements, setelements] = useState([]);

  const [user, setUser] = useState(userModel);
  const [userPassword, setUserPassword] = useState(null);
  const [users, setUsers] = useState([]);
  const [useUserTable, setUseUserTable] = useState(false);

  const [documentSki, setDocumentSki] = useState(cardModel);
  const [bookSkies, setBookSkies] = useState([]);
  const [useBookSkiTable, setUseBookSkiTable] = useState(false);

  const [documentSnowboard, setDocumentSnowboard] = useState(cardModel);
  const [bookSnowBoards, setBookSnowBoards] = useState([]);
  const [useBookSnowboardTable, setUseBookSnowboardTable] = useState(false);

  const [elementDialog, setElementDialog] = useState(false);
  const [userResetPasswordDialog, setUserResetPasswordDialog] = useState(false);
  const [deleteElementDialog, setDeleteElementDialog] = useState(false);

  const [deleteElementsDialog, setDeleteElementsDialog] = useState(false);

  const [selectedelements, setSelectedelements] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [nodes, setNodes] = useState(firebaseService.nodes);
  const [hasLoadNodes, setHasLoadNodes] = useState(false);
  const [value, setValue] = useState("");
  const [items, setItems] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "Български",
    localize: "bg",
  });
  const [selectedUserLanguage, setSelectedUserLanguage] = useState(1);
  const [selectUserAccessBook, setSelectUserAccessBook] = useState(1);
  const [selectRole, setSelectRole] = useState(2);
  const [disableEditField, setDisableEditField] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const dialogWidth = "67vw";

  useEffect(() => {
    switch (userSessionStorage.getSelectedTable()) {
      case "sk":
        skiBookHandler();
        break;
      case "sb":
        snowBoardBookHandler();
        break;
      case "us":
        usersHandler();
        break;
      default:
        usersHandler();
        break;
    }
  }, []);

  useEffect(() => {
    // sessionStorage.setItem("localize", "ff");
    userSessionStorage.setDefaultAdministratioinLanguage();
    firebaseService.getNodesData().then((res) => {
      setNodes(res);
    });
  }, [selectedLanguage, hasLoadNodes]);

  const openNew = () => {
    setDisableEditField(false);
    setUser({
      ...userModel,
      id: Math.round(Date.now() / 1000),
      cardId: "000000",
      class: "none",
      role: 2,
      hasNewUser: true,
      uab: 1,
      localize: 1,
    });

    setHasLoadNodes(!hasLoadNodes);
    setValue("");
    setUserPassword(null);
    setDocumentSki({ ...cardModel, localize: selectedLanguage?.localize });
    setDocumentSnowboard({
      ...cardModel,
      localize: selectedLanguage?.localize,
    });

    setSubmitted(false);
    setElementDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setElementDialog(false);
  };

  const hideDialogUserResetPassword = () => {
    setUserResetPasswordDialog(false);
  };

  const hideDeleteElementDialog = () => {
    setDeleteElementDialog(false);
  };

  const hideDeleteElementsDialog = () => {
    setDeleteElementsDialog(false);
  };

  const search = (event, book) => {
    //setItems([...Array(10).keys()].map((item) => event.query + ">" + item));
    let searchParams =
      book && book == Books[0].value
        ? bookSkies.map((s) => s.name + ">")
        : book && book == Books[1].value
        ? bookSnowBoards.map((s) => s.name + ">")
        : [];

    setItems(
      searchParams.filter(
        (item) =>
          String(item)
            .toLowerCase()
            .includes(String(event.query).toLowerCase()) ||
          bookModifierService
            .convertLatinToCyrillic(String(item).toLowerCase())
            .includes(String(event.query).toLowerCase()) ||
          String(item)
            .toLowerCase()
            .includes(
              bookModifierService
                .convertLatinToCyrillic(String(event.query))
                .toLowerCase()
            ) ||
          bookModifierService
            .convertLatinToCyrillic(String(item).toLowerCase())
            .includes(
              bookModifierService.convertLatinToCyrillic(
                String(event.query).toLowerCase()
              )
            )
      )
    );
  };

  function createOrModifyNode(
    node,
    key,
    nodeNames,
    index,
    nodeUrl,
    changeNode,
    localize,
    isNewDocument,
    book
  ) {
    let result = null;
    if (!node) {
      node = {
        ...nodeModel,
        id: changeNode ? null : `${key}|${book}|${localize}`,
        key: `${key}`,
        url: nodeUrl,
        label: nodeNames[nodeNames.length - 1],
        localize: localize,
        children: null,
      };
    }

    if (!changeNode) {
      changeNode = node;
    }

    if (node.children) {
      let idx = ++index;
      //let extendKey = `${key}-${node.key}`                         //old thing was idx !!!
      let nodeChildren = node.children.find(
        (a) => a.label == nodeNames[idx]
        //TODO: this filter might not be needed
        //   && a.id?.includes(book) ||
        // a.id?.children[0]?.includes(book)
      );

      let changedChildren = createOrModifyNode(
        nodeChildren,
        nodeChildren?.key
          ? nodeChildren?.key
          : `${node.key}-${node.children.length}`,
        nodeNames,
        idx,
        nodeUrl,
        changeNode,
        localize,
        isNewDocument,
        book
      );

      (() => {
        for (const key in node.children) {
          //console.log(node.children[key]);
          if (node.children[key]?.key == changedChildren?.key) {
            node.children[key] = changedChildren;
          }
        }
      })();
    } else if (isNewDocument) {
      //   console.log(node);
      let newNode = null;
      if (
        node.children == null &&
        nodeNames.length !== node.key?.split("-").length
      ) {
        newNode = {
          ...node,
          url: null,
          children: [
            {
              ...node,
              id: null,
              key: `${node.key}-${0}`,
              localize: localize,
            },
          ],
        };
        //console.log(newNode);
        node = newNode;
      } else if (nodeNames.length === node.key?.split("-").length) {
        let keys = [];
        bookModifierService.getNodeByName(
          changeNode,
          nodeNames[nodeNames.length - 2],
          keys
        );
        let previousNode = keys && keys[0];
        //console.log(previousNode);
        previousNode?.children.push(node);
        //console.log(changeNode);
      }
      //console.log(changeNode);
      //   console.log(node);
      let childrenCountIdx = node.children?.length;
      if (node.children) {
        node.children.push({
          ...nodeModel,
          id: null,
          key: `${node.key}-${childrenCountIdx}`,
          url: nodeUrl,
          label: nodeNames[nodeNames.length - 1],
          localize: localize,
          children: null,
        });
        //console.log(node);
        //   console.log(firstNodePosition);
      }
      return node;
    } else {
      result = changeNode;
    }
    return (result = node);
  }

  const saveElement = () => {
    setSubmitted(true);
    if (useUserTable) {
      firebaseService
        .setUser({
          ...user,
          hash: user.hasNewUser ? crypt(user.hash) : userPassword,
          hasPasswordToUpdate:
            !user.hasNewUser && user.hash ? crypt(user.hash) : false,
        })
        .then((res) => {
          setElementDialog(false);
          setUser(userModel);
          usersHandler();
          //console.log(res);
          if (res?.authError) {
            toast.current.show({
              severity: "error",
              summary: "Грешка",
              detail: `${String(res?.authError).replace("Firebase:", "")}`,
              life: 5000,
            });
          }
        })
        .catch((err) => {
          //handle error
          //console.log(err);
        });
    } else if (useBookSkiTable) {
      let nodeNames = String(value).trim().split(">");
      //   let nodeLabel = nodeNames && nodeNames[nodeNames.length - 1];
      const ski = Books[0].value;
      let nodeUrl = bookModifierService.urlPreparater(
        String(value).trim(),
        selectedLanguage.localize,
        ski
      );

      if (!nodeUrl) {
        toast.current.show({
          severity: "error",
          summary: "Грешка",
          detail: "Грешка в документа",
          life: 3000,
        });
      }

      let skiId = bookModifierService.namePathConverter(nodeUrl);
      let primaryNode = nodes.find(
        (a) => a.label == nodeNames[0] && a.id.includes(ski)
      );

      let nodeId = null;
      if (documentSki.nodeId == "") {
        // console.log("entrance node change");
        let primaryNodeExtended = createOrModifyNode(
          primaryNode,
          primaryNode?.key ? primaryNode?.key : nodes.length,
          nodeNames,
          0,
          nodeUrl,
          null,
          selectedLanguage.localize,
          documentSki.nodeId == "",
          ski
        );

        // if (!primaryNode) {
        //   primaryNode = {
        //     ...nodeModel,
        //     id: `${nodes.length}|${ski}|${documentSki.localize}`,
        //     key: `${nodes.length}`,
        //     url: nodeUrl,
        //     label: nodeNames[nodeNames.length - 1],
        //     localize: documentSki.localize,
        //     children: null,
        //   };
        // }

        let nodeKeys = [];
        bookModifierService.getNodeByName(
          primaryNodeExtended,
          nodeNames[nodeNames.length - 1],
          (nodeKeys = [])
        );

        nodeId = nodeKeys.length > 0 ? nodeKeys[0]?.key : "none";

        firebaseService.setNode(primaryNodeExtended).then(() => {
          firebaseService.getNodesData().then(() => {
            //if need call firebase get db nodes;
          });
        });
      }

      firebaseService
        .setDataToDb({
          ...documentSki,
          name: value,
          localize: selectedLanguage.localize,
          collectionGroup: "skies",
          id: skiId,
          nodeId: nodeId ?? documentSki.nodeId, //nodeKey,
        })
        .then((res) => {
          setValue("");
          setElementDialog(false);
          setDocumentSki(cardModel);
          skiBookHandler();
        });
    } else if (useBookSnowboardTable) {
      let nodeNames = String(value).trim().split(">");
      //   let nodeLabel = nodeNames && nodeNames[nodeNames.length - 1];
      const snowboard = Books[1].value;
      let nodeUrl = bookModifierService.urlPreparater(
        String(value).trim(),
        selectedLanguage.localize,
        snowboard
      );

      if (!nodeUrl) {
        toast.current.show({
          severity: "error",
          summary: "Грешка",
          detail: "Грешка в документа",
          life: 3000,
        });
      }

      let snowBordId = bookModifierService.namePathConverter(nodeUrl);
      let primaryNode = nodes.find(
        (a) => a.label == nodeNames[0] && a.id.includes(snowboard)
      );

      let nodeId = null;
      if (documentSnowboard.nodeId == "") {
        // console.log("entrance node change");
        let primaryNodeExtended = createOrModifyNode(
          primaryNode,
          primaryNode?.key ? primaryNode?.key : nodes.length,
          nodeNames,
          0,
          nodeUrl,
          null,
          selectedLanguage.localize,
          documentSnowboard.nodeId == "",
          snowboard
        );

        // if (!primaryNode) {
        //   primaryNode = {
        //     ...nodeModel,
        //     id: `${nodes.length}|${snowboard}|${documentSnowboard.localize}`,
        //     key: `${nodes.length}`,
        //     url: nodeUrl,
        //     label: nodeNames[nodeNames.length - 1],
        //     localize: documentSnowboard.localize,
        //     children: null,
        //   };
        // }

        let nodeKeys = [];
        bookModifierService.getNodeByName(
          primaryNodeExtended,
          nodeNames[nodeNames.length - 1],
          (nodeKeys = [])
        );

        nodeId = nodeKeys.length > 0 ? nodeKeys[0]?.key : "none";

        firebaseService.setNode(primaryNodeExtended).then(() => {
          firebaseService.getNodesData().then(() => {
            //if need call firebase get db nodes;
          });
        });
      }

      firebaseService
        .setDataToDb({
          ...documentSnowboard,
          name: value,
          localize: selectedLanguage.localize,
          collectionGroup: "snowboards",
          id: snowBordId,
          nodeId: nodeId ?? documentSnowboard.nodeId,
        })
        .then((res) => {
          setValue("");
          setElementDialog(false);
          setDocumentSnowboard(cardModel);
          snowBoardBookHandler();
        });
    }
    // sessionStorage.setItem("localize", selectedLanguage.localize);
  };

  const editElement = (rowData) => {
    // console.log(rowData);
    setDisableEditField(true);
    if (useUserTable) {
      setUser({
        ...rowData,
        hash: null,
      });
      setUserPassword(rowData.hash);
    } else if (useBookSkiTable) {
      setValue(rowData.name);
      setSelectedLanguage(Languges.find((a) => a.localize == rowData.localize));
      setDocumentSki({ ...rowData });
    } else if (useBookSnowboardTable) {
      setValue(rowData.name);
      setSelectedLanguage(Languges.find((a) => a.localize == rowData.localize));
      setDocumentSnowboard({ ...rowData });
    }
    setElementDialog(true);
  };

  const confirmUserResetPassword = (rowData) => {
    setUserResetPasswordDialog(true);
    setUser(rowData);
  };

  const userResetPasswordHandler = () => {
    firebaseService
      .userResetPassword(user)
      .then((res) => {
        setUserResetPasswordDialog(false);
        setUser(userModel);
        toast.current.show({
          severity: "success",
          summary: "Успешно",
          detail: "Изпратен линк за реален 'email' за възстановяване на парола",
          life: 3000,
        });
      })
      .catch((error) => {
        setUserResetPasswordDialog(false);
        toast.current.show({
          severity: "error",
          summary: "Грешка",
          detail: `Несъществуващ email '${user?.email}' за възстановяване на парола`,
          life: 3000,
        });
      });
  };

  const confirmDeleteElement = (rowData) => {
    setDeleteElementDialog(true);
    if (useUserTable) {
      setUser(rowData);
    } else if (useBookSkiTable) {
      setDocumentSki(rowData);
    } else if (useBookSnowboardTable) {
      setDocumentSnowboard(rowData);
    }
  };

  const deleteElement = () => {
    if (useUserTable) {
      firebaseService
        .deleteUserFromDb(user)
        .then((res) => {
          setDeleteElementDialog(false);
          setUser(userModel);
          toast.current.show({
            severity: "success",
            summary: "Успешно",
            detail: "Потребителят е изтрит",
            life: 3000,
          });
          usersHandler();
        })
        .catch((err) => {
          // console.log(err);
        });
    } else if (useBookSkiTable) {
      //   let node = nodes.find(
      //     (e) => e.id == `${documentSki.nodeId}|${documentSki.localize}`
      //   );
      //   if (node) {
      //     firebaseService.deleteNodeById(node).then(() => {});
      //   }

      firebaseService
        .deleteDataFromDbByDocId({ ...documentSki, collectionGroup: "skies" })
        .then((res) => {
          setDeleteElementDialog(false);
          setDocumentSki(cardModel);
          toast.current.show({
            severity: "success",
            summary: "Успешно",
            detail: "Документът е изтрит",
            life: 3000,
          });
          skiBookHandler();
        })
        .catch((err) => {
          // console.log(err);
        });
    } else if (useBookSnowboardTable) {
      firebaseService
        .deleteDataFromDbByDocId({
          ...documentSnowboard,
          collectionGroup: "snowboards",
        })
        .then((res) => {
          setDeleteElementDialog(false);
          setDocumentSnowboard(cardModel);
          toast.current.show({
            severity: "success",
            summary: "Успешно",
            detail: "Документът е изтрит",
            life: 3000,
          });
          snowBoardBookHandler();
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  //const findIndexById = (id) => {
  // let index = -1;
  // for (let i = 0; i < elements.length; i++) {
  //     if (elements[i].id === id) {
  //         index = i;
  //         break;
  //     }
  // }
  // return index;
  //};

  //const createId = () => {
  //  let id = "";
  // let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // for (let i = 0; i < 5; i++) {
  //     id += chars.charAt(Math.floor(Math.random() * chars.length));
  // }
  //  return id;
  //};

  // const importCSV = (e) => {
  //     const file = e.files[0];
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //         const csv = e.target.result;
  //         const data = csv.split('\n');

  //         // Prepare DataTable
  //         const cols = data[0].replace(/['"]+/g, '').split(',');
  //         data.shift();

  //         const importedData = data.map(d => {
  //             d = d.split(',');
  //             const processedData = cols.reduce((obj, c, i) => {
  //                 c = c === 'Status' ? 'inventoryStatus' : (c === 'Reviews' ? 'rating' : c.toLowerCase());
  //                 obj[c] = d[i].replace(/['"]+/g, '');
  //                 (c === 'price' || c === 'rating') && (obj[c] = parseFloat(obj[c]));
  //                 return obj;
  //             }, {});

  //             processedData['id'] = createId();
  //             return processedData;
  //         });

  //         const _elements = [...elements, ...importedData];

  //         setelements(_elements);
  //     };

  //     reader.readAsText(file, 'UTF-8');
  // }

  // const exportCSV = () => {
  //     dt.current.exportCSV();
  // }

  // const confirmDeleteSelected = () => {
  //   setDeleteElementsDialog(true);
  // };

  const deleteSelectedelements = () => {
    // let _elements = elements.filter(val => !selectedelements.includes(val));
    // setelements(_elements);
    // setDeleteelementsDialog(false);
    // setSelectedelements(null);
    toast.current.show({
      severity: "success",
      summary: "Успешно",
      detail: "Елементите са изтрити",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    if (useUserTable) {
      let _user = { ...user };
      _user[`${name}`] = val;
      setUser(_user);
    } else if (useBookSkiTable) {
      let _documentSki = { ...documentSki };
      _documentSki[`${name}`] = val;
      setDocumentSki(_documentSki);
    } else if (useBookSnowboardTable) {
      let _documentSnowboard = { ...documentSnowboard };
      _documentSnowboard[`${name}`] = val;
      setDocumentSnowboard(_documentSnowboard);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="card">
          <Button
            label="Начало"
            icon="pi pi-home"
            className="p-button-outlined p-button-secondary mr-2"
            onClick={(event) => {
              navigate("/");
              //sessionStorage.removeItem("localize");
              // props.changeLanguageClick(true);
              userSessionStorage.setDefaultLanguage();
            }}
          />
          <Button
            label="Нов"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          {/* <Button
          label="Изтриване"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedelements || !selectedelements.length}
        /> */}
          {/* {importDataToDb()} */}
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* <FileUpload mode="basic" name="demo[]" auto url="https://primefaces.org/primereact/showcase/upload.php" accept=".csv" chooseLabel="Import" className="mr-2 inline-block" onUpload={importCSV} />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
        <div className="card">
          <Button
            label="Ски учебник"
            icon="pi pi-book"
            className="p-button-prime mr-2"
            onClick={() => {
              skiBookHandler();
              sessionStorage.setItem("tbl", "sk");
            }}
          />
          <Button
            label="Сноуборд учебник"
            icon="pi pi-book"
            className="p-button-prime mr-2"
            onClick={() => {
              snowBoardBookHandler();
              sessionStorage.setItem("tbl", "sb");
            }}
          />
          <Button
            label="Потребители"
            icon="pi pi-user"
            className="p-button-help"
            onClick={() => {
              usersHandler();
              sessionStorage.setItem("tbl", "us");
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  // const imageBodyTemplate = (rowData) => {
  //   return (
  //     <img
  //       src={`images/product/${rowData.image}`}
  //       onError={(e) =>
  //         (e.target.src =
  //           "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
  //       }
  //       alt={rowData.image}
  //       className="product-image"
  //     />
  //   );
  // };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editElement(rowData)}
        />
        <Button
          icon="pi pi-user-minus"
          className="p-button-rounded p-button-help mr-2"
          onClick={() => confirmUserResetPassword(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteElement(rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">
        Управление на{" "}
        {Books.find((b) => b.code == userSessionStorage.getSelectedTable())
          ?.name ?? "Потребители"}
      </h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Търсене..."
        />
      </span>
    </div>
  );

  const headerDialog = `
    Детайли за 
    ${
      Books.find((b) => b.code == userSessionStorage.getSelectedTable())
        ?.name ?? "Потребител"
    }
  `;

  const elementDialogFooter = (
    <React.Fragment>
      <Button
        label="Отказ"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Запази"
        disabled={
          useUserTable &&
          (!isEmailValid(user?.email) ||
            (user?.hash?.length > 0 && user?.hash?.length < 6))
        }
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveElement}
      />
    </React.Fragment>
  );

  const userResetPasswordDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialogUserResetPassword}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={userResetPasswordHandler}
      />
    </React.Fragment>
  );

  const deleteElementDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteElementDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteElement}
      />
    </React.Fragment>
  );

  const deleteElementsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteElementsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedelements}
      />
    </React.Fragment>
  );

  const usersHandler = () => {
    setUseUserTable(true);
    setUseBookSkiTable(false);
    setUseBookSnowboardTable(false);
    setBookSkies([]);
    setBookSnowBoards([]);
    setLoading(false);
    let usersData = [];
    firebaseService
      .getUsers({ commonGroup: "users" })
      .then((res) => {
        res.forEach((doc) => {
          let data = doc.data();
          // data = { ...data, uab: columnUserBookAccess(data?.uab) };
          usersData.push(data);
        });
      })
      .then(() => {
        //console.log(users);
        const usersDescending = [...usersData].sort((a, b) => b.id - a.id);
        setelements(usersDescending);
        setUsers(usersDescending);
      })
      .catch((res) => {
        toast.current.show({
          severity: "error",
          summary: "Грешка",
          detail: `${res}`,
          life: 3000,
        });

        setTimeout(() => {
          userSessionStorage.clearSession();
          firebaseService.signOutUser();
          setHasLoadNodes(!hasLoadNodes);
          window.location.reload();
        }, 1000);
      });
    setLoading(true);
  };

  const skiBookHandler = () => {
    setUseUserTable(false);
    setUseBookSkiTable(true);
    setUseBookSnowboardTable(false);
    setUsers([]);
    setBookSnowBoards([]);
    setLoading(false);
    let skiesData = [];
    firebaseService
      .getDataFromDb({ collectionGroup: "skies" })
      .then((res) => {
        res.forEach((doc) => {
          let data = doc.data();
          skiesData.push(data);
        });
      })
      .then(() => {
        // console.log(skiesData);
        setelements(skiesData);
        setBookSkies(skiesData);
      });
    setLoading(true);
  };

  const snowBoardBookHandler = () => {
    setUseUserTable(false);
    setUseBookSkiTable(false);
    setUseBookSnowboardTable(true);
    setUsers([]);
    setBookSkies([]);
    setLoading(false);
    let snowboardsData = [];
    firebaseService
      .getDataFromDb({ collectionGroup: "snowboards" })
      .then((res) => {
        res.forEach((doc) => {
          let data = doc.data();
          snowboardsData.push(data);
        });
      })
      .then(() => {
        // console.log(snowboardsData);
        setelements(snowboardsData);
        setBookSnowBoards(snowboardsData);
      });
    setLoading(true);
  };

  const columnUserBookAccess = (uab) => {
    let result = UserBookAccess.find((a) => a.value == uab)?.name ?? "отказан";
    return result;
  };

  const columnRole = (role) => {
    let result = SelectRoles.find((a) => a.value == role)?.name ?? "няма";
    return result;
  };

  //export data by collection name
  // let collection = [];
  //  firebaseService.getDataByCollection("nodes").then((res)=>{
  //   collection = res;

  //  }).then(()=>{
  //   exportData(collection)
  //  })

  //import data by collection name
  // const onUpload = (data) => {
  //   const file = data?.files[0];
  //   // if (data?.firebaseKey?.type !== "application/json") {
  //   //   console.log("Only JSON files are allowed.", "Error");
  //   //   return;
  //   // }
  //   const reader = new FileReader();

  //   reader.readAsText(file);
  //   const text = reader;

  //   const dbName = file.name.split(".")[0];

  //   setTimeout(() => {
  //     const jsonArray = JSON.parse(text.result);
  //     jsonArray.forEach((element) => {
  //       console.log(element);
  //       if (dbName.includes("nodes")) {
  //         firebaseService.setNode(element).then().catch();
  //       } else {
  //         setTimeout(() => {
  //           firebaseService
  //             .setDataToDb({
  //               ...element,
  //               collectionGroup: dbName,
  //               id: element.id,
  //             })
  //             .then()
  //             .catch();
  //         }, 100);
  //       }
  //     });
  //   }, 100);

  //   // toast.current.show({
  //   //   severity: "info",
  //   //   summary: "Success",
  //   //   detail: "File Uploaded",
  //   // });
  // };

  // const importDataToDb = () => {
  //   return (
  //     <>
  //       <FileUpload
  //         mode="basic"
  //         // name="demo[]"
  //         // url="/api/upload"
  //         accept=".json"
  //         maxFileSize={1000000}
  //         onSelect={onUpload}
  //         // auto
  //         chooseLabel="import"
  //       />
  //     </>
  //   );
  // };

  // function resizeInputArea(text) {
  //   //console.log(text);
  //   const result = text.length - (windowInnerWidht* 0.15) ;
  //   //console.log(result);
  //   return !result || result < 40? 40 : result;
  // }

  // function NewLineToBr({children = ""}){
  //   return children.split('\n').reduce(function (arr,line) {
  //     return arr.concat(
  //       line,
  //       <br />
  //     );
  //   },[])}

  function GetRows(text) {
    const calculation = Math.abs(
      text.split(";")?.length +
        text.split(":")?.length +
        text.split(".")?.length -
        2
    );
    return calculation;
  }

  return (
    loading && (
      <>
        <div className="datatable-crud-demo">
          <Toast ref={toast} />

          <div className="card">
            <Toolbar
              className="mb-4"
              start={leftToolbarTemplate}
              end={rightToolbarTemplate}
            ></Toolbar>
            <DataTable
              className="datatable-responsive"
              key={Date.now.toString()}
              ref={dt}
              value={elements}
              emptyMessage={"Няма данни"}
              selection={selectedelements}
              onSelectionChange={(e) => setSelectedelements(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Показване на {first} до {last} от {totalRecords} записа"
              globalFilter={globalFilter}
              header={header}
            >
              {/* {elements.map((field)=>{
                    console.log(field);
                    <Column
                    key={field.id}
                    field={field.firstName}
                    header="Name"
                    sortable
                    style={{ minWidth: "16rem" }}
                    ></Column>
                })} */}

              {/* <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
                exportable={false}
              ></Column> */}
              <Column
                // useUserTable
                field="id"
                header="инд."
                hidden={!useUserTable}
                style={{ minWidth: "2rem" }}
              />
              <Column
                field="firstname"
                header="Име"
                hidden={!useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="lastname"
                header="Фамилия"
                hidden={!useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field={(e) => columnUserBookAccess(e?.uab)}
                header="Достъп до"
                hidden={!useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="cardId"
                header="Карта №"
                hidden={!useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="class"
                header="Клас"
                hidden={!useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="email"
                header="Имейл"
                hidden={!useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field=""
                header="Парола"
                hidden={true}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field={(e) => columnRole(e?.role)}
                header="роля"
                hidden={!useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="name"
                header="Име"
                hidden={useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="h3"
                header="Заглавие"
                hidden={useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="h5"
                header="Под заглавие"
                hidden={useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="p"
                header="Параграф"
                hidden={useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="methodology"
                header="Методология"
                hidden={useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="description"
                header="Описание"
                hidden={useUserTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="learnErrors"
                header="Грешки"
                hidden={!useBookSkiTable}
                style={{ minWidth: "8rem" }}
              />
              <Column
                field="img"
                header="Имена на картинки"
                hidden={useUserTable}
                style={{ minWidth: "2rem", maxWidth: "9rem" }}
              />
              <Column
                field="videoName"
                header="Име на видео"
                hidden={useUserTable}
                style={{ minWidth: "2rem", maxWidth: "8rem" }}
              />
              <Column
                field="localize"
                header="Език"
                hidden={useUserTable}
                style={{ minWidth: "3rem", maxWidth: "3rem" }}
              />
              <Column
                header="Действия"
                body={actionBodyTemplate}
                exportable={false}
                style={{ minWidth: "4rem" }}
              ></Column>
              {/* <Column field="image" header="Image" body={imageBodyTemplate}></Column> */}
              {/* <Column field="price" header="Price" body={priceBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column> */}
              {/* <Column field="category" header="Category" sortable style={{ minWidth: '10rem' }}></Column> */}
              {/* <Column field="rating" header="Reviews" body={ratingBodyTemplate} sortable style={{ minWidth: '12rem' }}></Column> */}
              {/* <Column field="inventoryStatus" header="Status" body={statusBodyTemplate} sortable style={{ minWidth: '12rem' }}></Column> */}
            </DataTable>
          </div>

          <Dialog
            visible={elementDialog}
            style={{ width: dialogWidth }}
            header={headerDialog}
            modal
            className="p-fluid"
            footer={elementDialogFooter}
            onHide={hideDialog}
          >
            {/* <form onSubmit={handleSubmit(onCategoryChange)} className="flex flex-column gap-2"> */}
            {useUserTable && (
              <>
                <label id={user.id}>id: {user.id}</label>
                <br />
                <b>
                  <label className="mb-1" htmlFor="uab">
                    Достъп до учебник
                  </label>
                </b>
                <Dropdown
                  required
                  id={selectUserAccessBook}
                  value={user.uab}
                  onChange={(e) => {
                    setSelectUserAccessBook(e.value);
                    onInputChange(e, "uab");
                  }}
                  options={UserBookAccess}
                  optionLabel="name"
                  placeholder="Избор за достъп"
                />
                <b>
                  <label className="mb-1" htmlFor="email">
                    Имейл
                  </label>
                </b>
                <InputText
                  id="email"
                  type="email"
                  defaultValue={user.email}
                  onChange={(e) => onInputChange(e, "email")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !user.email,
                  })}
                />
                <b>
                  <label className="mb-1" htmlFor="password">
                    Парола
                  </label>
                </b>
                <InputText
                  id="password"
                  type="text"
                  required
                  defaultValue={user.hash}
                  onChange={(e) => onInputChange(e, "hash")}
                />
                <b>
                  <label className="mb-1" htmlFor="firstname">
                    Име
                  </label>
                </b>
                <InputText
                  id="firstname"
                  defaultValue={user.firstname}
                  onChange={(e) => onInputChange(e, "firstname")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !user.firstname,
                  })}
                />
                <b>
                  <label className="mb-1" htmlFor="lastname">
                    Фамилия
                  </label>
                </b>
                <InputText
                  id="lastname"
                  defaultValue={user.lastname}
                  onChange={(e) => onInputChange(e, "lastname")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !user.lastname,
                  })}
                />
                <b>
                  <label className="mb-1" htmlFor="cardId">
                    Карта №
                  </label>
                </b>
                <InputText
                  id="cardId"
                  defaultValue={user.cardId}
                  onChange={(e) => onInputChange(e, "cardId")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !user.cardId,
                  })}
                />
                <b>
                  <label className="mb-1" htmlFor="class">
                    Клас
                  </label>
                </b>
                <InputText
                  id="class"
                  defaultValue={user.class}
                  onChange={(e) => onInputChange(e, "class")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !user.class,
                  })}
                />
                <b>
                  <label className="mb-1" htmlFor="role">
                    Роля
                  </label>
                </b>
                <Dropdown
                  required
                  id="role"
                  value={user.role}
                  onChange={(e) => {
                    setSelectRole(e.value);
                    onInputChange(e, "role");
                  }}
                  options={SelectRoles}
                  optionLabel="name"
                  placeholder="Избор за роля"
                />
                <label className="mb-1" htmlFor="localize">
                  Език
                </label>
                <Dropdown
                  id="localize"
                  value={user.localize}
                  onChange={(e) => {
                    setSelectedUserLanguage(e.value);
                    onInputChange(e, "localize");
                  }}
                  options={UserLanguages}
                  optionLabel="name"
                  placeholder="Избор на език"
                />
              </>
            )}
            {(useBookSkiTable || useBookSnowboardTable) && (
              <>
                <b>
                  <label className="mb-1" htmlFor="name">
                    Име
                  </label>
                </b>
                <AutoComplete
                  id="name"
                  disabled={disableEditField}
                  value={value}
                  suggestions={items}
                  completeMethod={(e) =>
                    search(
                      e,
                      useBookSkiTable
                        ? "ski"
                        : useBookSnowboardTable
                        ? "snowboard"
                        : null
                    )
                  }
                  onChange={(e) => {
                    setValue(String(e.value));
                  }}
                  forceSelection={false}
                />
                <b>
                  <label className="mb-1" htmlFor="localize">
                    Език
                  </label>
                </b>
                <Dropdown
                  id="localize"
                  disabled={disableEditField}
                  value={selectedLanguage}
                  onChange={(e) => {
                    setSelectedLanguage(e.value);
                    sessionStorage.setItem("localize", e.value?.localize);
                    onInputChange(e.value?.localize, "localize");
                  }}
                  options={Languges}
                  optionLabel="name"
                  placeholder="Избор на език"
                />
                <b>
                  <label className="mb-1" htmlFor="h3">
                    Заглавие
                  </label>
                </b>
                <InputText
                  id="h3"
                  defaultValue={
                    useBookSkiTable ? documentSki.h3 : documentSnowboard.h3
                  }
                  onChange={(e) => onInputChange(e, "h3")}
                  required
                  className={classNames({
                    "p-invalid":
                      submitted &&
                      (useBookSkiTable
                        ? !documentSki.h3
                        : !documentSnowboard.h3),
                  })}
                />
                <b>
                  <label className="mb-1" htmlFor="h5">
                    Под заглавие
                  </label>
                </b>
                <InputText
                  id="h5"
                  defaultValue={
                    useBookSkiTable ? documentSki.h5 : documentSnowboard.h5
                  }
                  onChange={(e) => onInputChange(e, "h5")}
                  required
                  className={classNames({
                    "p-invalid":
                      submitted &&
                      (useBookSkiTable
                        ? !documentSki.h5
                        : documentSnowboard.h5),
                  })}
                />
                <b>
                  <label className="mb-1" htmlFor="p">
                    Параграф
                  </label>
                </b>
                <InputTextarea
                  rows={GetRows(
                    useBookSkiTable
                      ? documentSki.p
                      : useBookSnowboardTable
                      ? documentSnowboard.p
                      : 1
                  )}
                  style={{
                    resize: "vertical",
                  }}
                  id="p"
                  defaultValue={
                    useBookSkiTable ? documentSki.p : documentSnowboard.p
                  }
                  onChange={(e) => onInputChange(e, "p")}
                />
                <b>
                  <label className="mb-1" htmlFor="methodology">
                    Методология
                  </label>
                </b>
                <InputTextarea
                  rows={GetRows(
                    useBookSkiTable
                      ? documentSki.methodology
                      : useBookSnowboardTable
                      ? documentSnowboard.methodology
                      : 1
                  )}
                  style={{
                    resize: "vertical",
                  }}
                  id="methodology"
                  defaultValue={
                    useBookSkiTable
                      ? documentSki.methodology
                      : documentSnowboard.methodology
                  }
                  onChange={(e) => onInputChange(e, "methodology")}
                />
                <b>
                  <label className="mb-1" htmlFor="description">
                    Описание
                  </label>
                </b>
                <InputTextarea
                  rows={GetRows(
                    useBookSkiTable
                      ? documentSki.description
                      : useBookSnowboardTable
                      ? documentSnowboard.description
                      : 1
                  )}
                  style={{
                    resize: "vertical",
                    // height: inputAreaHeightDescription,
                  }}
                  id="description"
                  defaultValue={
                    useBookSkiTable
                      ? documentSki.description
                      : documentSnowboard.description
                  }
                  onChange={(e) => onInputChange(e, "description")}
                />
                <b>
                  <label className="mb-1" htmlFor="learn-errors">
                    Грешки
                  </label>
                </b>
                <InputTextarea
                  rows={GetRows(
                    useBookSkiTable
                      ? documentSki.learnErrors
                      : useBookSnowboardTable
                      ? documentSnowboard.learnErrors
                      : 1
                  )}
                  style={{
                    resize: "vertical",
                  }}
                  id="learn-errors"
                  defaultValue={
                    useBookSkiTable
                      ? documentSki.learnErrors
                      : documentSnowboard.learnErrors
                  }
                  onChange={(e) => onInputChange(e, "learnErrors")}
                />
                <b>
                  <label className="mb-1" htmlFor="img">
                    Име на картинка
                  </label>
                </b>
                <InputText
                  id="img"
                  defaultValue={
                    useBookSkiTable
                      ? String(documentSki.img)
                      : String(documentSnowboard.img)
                  }
                  onChange={(e) => onInputChange(e, "img")}
                />
                <b>
                  <label className="mb-1" htmlFor="videoName">
                    Име на видео
                  </label>
                </b>
                <InputText
                  id="videoName"
                  defaultValue={
                    useBookSkiTable
                      ? String(documentSki.videoName)
                      : String(documentSnowboard.videoName)
                  }
                  onChange={(e) => onInputChange(e, "videoName")}
                />
              </>
            )}
            {/* </form> */}
          </Dialog>

          <Dialog
            visible={userResetPasswordDialog}
            style={{ width: "450px" }}
            header="Потвърждение"
            modal
            footer={userResetPasswordDialogFooter}
            onHide={hideDialogUserResetPassword}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {user && (
                <span>
                  Сигурни ли сте, че искате вьзстановяване на паролата за{" "}
                  <b>{user.email}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteElementDialog}
            style={{ width: "450px" }}
            header="Потвърждение"
            modal
            footer={deleteElementDialogFooter}
            onHide={hideDeleteElementDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {user && (
                <span>
                  Сигурни ли сте, че искате да изтриете{" "}
                  <b>
                    {useUserTable
                      ? user.firstname
                        ? user.firstname
                        : user.email
                      : useBookSkiTable
                      ? documentSki.name
                      : useBookSnowboardTable
                      ? documentSnowboard.name
                      : null}
                  </b>
                  ?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteElementsDialog}
            style={{ width: "450px" }}
            header="Потвърждение"
            modal
            footer={deleteElementsDialogFooter}
            onHide={hideDeleteElementsDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              {user && (
                <span>
                  Сигурни ли сте, че искате да изтриете избраните елементи?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </>
    )
  );
};

export default Administration;
