export const videoModel = {
    id: 0,
    name: "",
    videoType: "",
    videoUrl: "",
    width: "100%",
    height: "100%",
};

export const cardModel = {
    id: 0,
    name: "",
    h3: "",
    h5: "",
    p: "",
    img: "",
    videoName: "",
    description: "",
    methodology: "",
    learnErrors: "",
    localize: "bg",
    nodeId: "",
};

export const iframeModel = {
    url: "",
}

export const userModel = {
    id: 0,
    role: 2,
    cardId: "",
    class: "",
    email: "",
    firstname: "",
    lastname: "",
    hash: "",
    uab: 1,
    localize: 1,
}

export const nodeModel = {
    id: null,
    key: "",
    label: "",
    children: null,
    url: "",
    localize: ""
}

export const imageGaleriaModel = {
    itemImageSrc: "",
    thumbnailImageSrc: "",
    alt: "",
    title: "",
}

export const pswsHashes = {
    id: 0,
    token: "",
    hash: ""
}