import React, { Suspense, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import { ProgressSpinner } from "primereact/progressspinner";

import { AuthContext } from "../../../context/AuthContext";

function Logout(props) {
  const authenticationContext = useContext(AuthContext);
  const { logout, isAuthenticated } = authenticationContext;
  const navigate = useNavigate();

  logout();
  useEffect(() => {
    props.changeLanguageClick(true);
    navigate("/");
  }, []);

  return (
    <>
      <div hidden={!true}>
        {/* <ProgressSpinner
          className="login-progress-spinner"
          style={{
            width: "100px",
            height: "100px",
            position: "fixed",
            padding: "30px",
          }}
          strokeWidth="1"
          fill="var(--surface-ground)"
          animationDuration="1s"
        /> */}
      </div>
    </>
  );
}

export default Logout;
