/* eslint-disable import/no-anonymous-default-export */

import { nodeModel } from "../common/models";
//   let newNode = null;
//   let firstNodePosition = nodes.find((a) => a.label == nodeNames[0]);
//   let nodeKey = firstNodePosition?.key;

//   if (!firstNodePosition?.children && firstNodePosition?.label !== nodeLabel) {
//     nodeKey = nodes.length;
//     //console.log(nodeKey);
//   }

//   if (!firstNodePosition) {
//     firstNodePosition = {
//         ...nodeModel,
//         id: `${nodeKey}|${documentSki.localize}`,
//         key: nodeKey,
//         label: nodeLabel,
//         url: nodeUrl,
//         children: null,
//         localize: documentSki.localize,
//       };
//   }

//   if (firstNodePosition?.chilrden) {
//     console.log(firstNodePosition?.chilrden);
//   }
//   if (nodeNames.length == 2 && firstNodePosition.children==null) {
//     console.log('we hit empty child')
//     newNode = {
//       ...firstNodePosition,
//       url: null,
//       children: [
//         {
//           ...firstNodePosition,
//           id: null,
//           key: `${firstNodePosition.key}-${0}`,
//         },
//       ],
//     };
//     firstNodePosition = newNode;
//   }

//   if (nodeNames.length == 2 && firstNodePosition.children) {
//     let idx = firstNodePosition.children.length;
//     firstNodePosition.children.push({
//       ...nodeModel,
//       key: `${firstNodePosition.key}-${idx}`,
//       url: nodeUrl,
//       label: nodeNames[nodeNames.length - 1],
//       children: null,
//     });
//     console.log(firstNodePosition);
//   }

//   if (documentSki?.nodeId != "") {
//     console.log(documentSki?.nodeId);
//   }

//   let firstNodePosition = processNode(
//     nodes,
//     nodeNames,
//     nodeLabel,
//     nodeUrl,
//     documentSki
//   );

function convertCyrillicToLatin(word) {
  let convertWord = [];
  if (!word) {
    return null;
  }

  let arr = String(word).split("");
  arr.forEach((char) => {
    switch (char) {
      case "а":
        convertWord.push("a");
        break;
      case "б":
        convertWord.push("b");
        break;
      case "в":
        convertWord.push("v");
        break;
      case "г":
        convertWord.push("g");
        break;
      case "д":
        convertWord.push("d");
        break;
      case "е":
        convertWord.push("e");
        break;
      case "ж":
        convertWord.push("j");
        break;
      case "з":
        convertWord.push("z");
        break;
      case "и":
        convertWord.push("i");
        break;
      case "й":
        convertWord.push("i");
        break;
      case "к":
        convertWord.push("k");
        break;
      case "л":
        convertWord.push("l");
        break;
      case "м":
        convertWord.push("m");
        break;
      case "н":
        convertWord.push("n");
        break;
      case "о":
        convertWord.push("o");
        break;
      case "п":
        convertWord.push("p");
        break;
      case "р":
        convertWord.push("r");
        break;
      case "с":
        convertWord.push("s");
        break;
      case "т":
        convertWord.push("t");
        break;
      case "у":
        convertWord.push("u");
        break;
      case "ф":
        convertWord.push("f");
        break;
      case "х":
        convertWord.push("x");
        break;
      case "ц":
        convertWord.push("c");
        break;
      case "ч":
        convertWord.push("ch");
        break;
      case "ш":
        convertWord.push("sh");
        break;
      case "щ":
        convertWord.push("sht");
        break;
      case "ъ":
        convertWord.push("a");
        break;
      case "ь":
        convertWord.push("a");
        break;
      case "ю":
        convertWord.push("iu");
        break;
      case "я":
        convertWord.push("ia");
        break;
      default:
        convertWord.push(char);
        break;
    }
  });

  return convertWord.join("");
}

function convertLatinToCyrillic(word) {
  let convertWord = [];
  if (!word) {
    return null;
  }

  let arr = String(word).split("");
  arr.forEach((char) => {
    switch (char) {
      case "a":
        convertWord.push("а");
        break;
      case "b":
        convertWord.push("б");
        break;
      case "v":
        convertWord.push("в");
        break;
      case "g":
        convertWord.push("г");
        break;
      case "d":
        convertWord.push("д");
        break;
      case "e":
        convertWord.push("е");
        break;
      case "j":
        convertWord.push("ж");
        break;
      case "z":
        convertWord.push("з");
        break;
      case "i":
        convertWord.push("и");
        break;
      // case "i":
      //   convertWord.push("й");
      //   break;
      case "k":
        convertWord.push("к");
        break;
      case "l":
        convertWord.push("л");
        break;
      case "m":
        convertWord.push("м");
        break;
      case "n":
        convertWord.push("н");
        break;
      case "o":
        convertWord.push("о");
        break;
      case "p":
        convertWord.push("п");
        break;
      case "r":
        convertWord.push("р");
        break;
      case "sht":
        convertWord.push("щ");
        break;
      case "sh":
        convertWord.push("ш");
        break;
      case "s":
        convertWord.push("с");
        break;
      case "t":
        convertWord.push("т");
        break;
      case "u":
        convertWord.push("у");
        break;
      case "f":
        convertWord.push("ф");
        break;
      case "h":
        convertWord.push("х");
        break;
      case "ch":
        convertWord.push("ч");
        break;
      case "c":
        convertWord.push("ц");
        break;
      // case "ъ":
      //   convertWord.push("a");
      //   break;
      // case "ь":
      //   convertWord.push("a");
      //   break;
      // case "ю":
      //   convertWord.push("iu");
      //   break;
      // case "я":
      //   convertWord.push("ia");
      //   break;
      default:
        convertWord.push(char);
        break;
    }
  });

  return convertWord.join("");
}

function getImagePathByName(name) {
  let extension = "jpg";
  const imageName = name?.split(".");
  if (imageName.length > 1) {
    let currentExtension = imageName[imageName.length - 1];
    if (
      String(currentExtension) == "gif" ||
      String(currentExtension) == "jpg" ||
      String(currentExtension) == "GIF" ||
      String(currentExtension) == "JPG"
    ) {
      return `/images/${name}`;
    }
  }
  return `/images/${name}.${extension}` || `/images/${name}.${extension.toUpperCase()}`;
}

function routeParser(path) {
  let charArray = String(path).split("|");
  return charArray.join("/");
}

function namePathConverter(name) {
  let charArray = String(name).split("/");
  return charArray.join("|");
}

function nameSpaceToPathConverter(name) {
  let charArray = String(name).split(" ");
  return charArray.join("-");
}

function urlPreparater(name, localize, book) {
  let latinName = convertCyrillicToLatin(String(name).toLowerCase());
  if (!latinName) {
    return null;
  }
  let pathArray = latinName.split(">");
  return `/${book}/${localize}/${nameSpaceToPathConverter(
    pathArray.join("/")
  )}/`;
}

// function processNode(nodes, nodeNames, nodeLabel, nodeUrl, documentSki) {
//   let newNode = null;
//   let firstNodePosition = nodes.find((a) => a.label == nodeNames[0]);
//   let nodeKey = firstNodePosition?.key;

//   if (!firstNodePosition?.children && firstNodePosition?.label !== nodeLabel) {
//     nodeKey = nodes.length;
//     //console.log(nodeKey);
//   }

//   if (!firstNodePosition) {
//     firstNodePosition = {
//       ...nodeModel,
//       id: `${nodeKey}|${documentSki.localize}`,
//       key: nodeKey,
//       label: nodeLabel,
//       url: nodeUrl,
//       children: null,
//       localize: documentSki.localize,
//     };
//   }

//   if (firstNodePosition?.children) {
//     console.log(firstNodePosition?.children);
//   }

//   if (nodeNames.length == 2 && firstNodePosition.children == null) {
//     console.log("we hit empty child 3");
//     newNode = {
//       ...firstNodePosition,
//       url: null,
//       children: [
//         {
//           ...firstNodePosition,
//           id: null,
//           key: `${firstNodePosition.key}-${0}`,
//         },
//       ],
//     };
//     firstNodePosition = newNode;
//   }

//   // if (nodeNames.length == 3 && firstNodePosition.children.children == null) {
//   //   console.log("we hit empty child 3");
//   //   newNode = {
//   //     ...firstNodePosition,
//   //     url: null,
//   //     children: [
//   //       {
//   //         ...firstNodePosition.children[0],
//   //         children: {
//   //           ...firstNodePosition.children[0],
//   //           id: null,
//   //           key: `${firstNodePosition.key}-${0}-${0}`,
//   //         },
//   //       },
//   //     ],
//   //   };
//   //   firstNodePosition = newNode;
//   // }

//   if (nodeNames.length == 2 && firstNodePosition.children) {
//     let idx = firstNodePosition.children.length;
//     firstNodePosition.children.push({
//       ...nodeModel,
//       key: `${firstNodePosition.key}-${idx}`,
//       url: nodeUrl,
//       label: nodeNames[nodeNames.length - 1],
//       children: null,
//     });
//     console.log(firstNodePosition);
//   }

//   if (documentSki?.nodeId != "") {
//     console.log(documentSki?.nodeId);
//   }

//   return firstNodePosition; // You can return the updated firstNodePosition or any other data you need.
// }

function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

// function distinct(arr) {
//   let outputArray = arr.filter(function (v, i, self) {
//     return i == self.indexOf(v);
//   });

//   return outputArray;
// }

function isMember(arr, val) {
  if (!arr) {
    return null;
  }
  return arr.some(function (arrVal) {
    return val === arrVal;
  });
}

function getNodeByName(node, nodeName, keys = []) {
  if (node && node.label == nodeName && !isMember(keys, node)) {
    keys.push(node);
  }

  if (isIterable(node?.children)) {
    for (const item of node?.children) {
      if (item && item.label == nodeName && !isMember(keys, item)) {
        keys.push(item);
      }
      if (item?.children) {
        getNodeByName(item.children, nodeName, keys);
      }
    }
  } else if (node?.length > 0) {
    for (const item of node) {
      if (item && item.label == nodeName && !isMember(keys, item)) {
        keys.push(item);
      }
      if (item?.children) {
        getNodeByName(item.children, nodeName, keys);
      }
    }
    return keys;
  }
}

// function getNodeKey(menu, routes = []) {
//     for (const item of menu) {
//         debugger
//       if (item?.key) {
//         routes.push(item.key);
//       }
//       if (item.children) {
//         getNodeKey(item.children, routes);
//       }
//     }
//     return routes;
//   }

function updateNodeWithKey(node, changedNode) {
  // If children is null, create a new object
  if (!node.children) {
    return node.key ?? null;
  }

  let currentNode = node.children;
  console.log(node);
  for (let i = 0; i < node.children.length; i++) {
    const key = changedNode.key;
    if (!currentNode[key]) {
      currentNode[key] = [];
    }
    node = changedNode[key];
  }
}

// function getNodeByNameOld(node, nodeName) {
//   let keys = [];
//   if (!node?.children) {
//     return node;
//   }

//   for (const item of node?.children) {
//     if (item?.key && item.label == nodeName) {
//       keys.push(item);
//     }
//     if (item?.children) {
//       getNodeByName(item.children, keys);
//     }
//   }
//   return keys[0];
// }

function getNodeCollectionByName(node, nodeName) {
  let keys = [];
  if (!node?.children) {
    return node;
  }

  for (const item of node?.children) {
    if (item?.key && item.label == nodeName) {
      keys.push(item);
    }
    if (item?.children) {
      getNodeByName(item.children, keys);
    }
  }
  return keys;
}

export default {
  convertCyrillicToLatin,
  convertLatinToCyrillic,
  getImagePathByName,
  routeParser,
  namePathConverter,
  nameSpaceToPathConverter,
  urlPreparater,
  getNodeByName,
  getNodeCollectionByName,
  updateNodeWithKey,
};
