import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { classNames } from "primereact/utils";
import { Tree } from "primereact/tree";
import { Button } from "primereact/button";

//import nodes from "../../../common/nodes.json";
import firebaseService from "../../../services/firebase";
import userSessionStorage from "../../../services/userSessionStorage";

export default function TreeButton(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [path, setPath] = useState(
    location.pathname?.includes("/ski/")
      ? "ski"
      : location.pathname.includes("/snowboard/")
      ? "snowboard"
      : ""
  );
  const [nodes, setNodes] = useState(
    firebaseService.nodes.filter((e) => e.id?.includes(path))
  );

  useEffect(() => {
    firebaseService.getNodesData().then((res) => {
      setNodes(res.filter((e) => e.id?.includes(path)));
    });
  }, []);
  
  const nodeTemplate = (node, options) => {
    let label = <b>{node.label}</b>;

    if (node.url) {
      label = (
        <a
          href={node.url}
          className="text-primary hover:underline font-semibold"
        >
          {node.label}
        </a>
      );
    }

    return <span className={options.className}>{label}</span>;
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    const iconClassName = classNames("p-tree-toggler-icon pi pi-fw", {
      "pi-caret-right": !expanded,
      "pi-caret-down": expanded,
    });

    return (
      <button
        type="button"
        className="p-tree-toggler p-link"
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span className={iconClassName} aria-hidden="true"></span>
      </button>
    );
  };

  const administrationHandler = (e) => {
    navigate("/administration");
  };

  const handleDefaultBehavior = (e) => {
    e.preventDefault();
    const href = e?.target?.href;
    if (href) {
      const url = String(href).replace(process.env.REACT_APP_ENV_VARIABLE,"");
      navigate(`/${url}`);
    }
  };

  return (
    <>
      <div className="container-tree-button">
        {/* <div className="tree-container"> */}
        <Tree
          onClick={handleDefaultBehavior}
          value={nodes}
          nodeTemplate={nodeTemplate}
          togglerTemplate={togglerTemplate}
          className="rounded-tree"
        />
        <div hidden={userSessionStorage.getRole() !== "1"}>
          <Button
            className="tree-admin-button"
            label="Администриция"
            onClick={administrationHandler}
          />
        </div>
        {/* </div> */}
      </div>
    </>
  );
}
