import React, { useState, useEffect } from "react";

import TreeButton from "../tree/TreeButton";
import Card from "../../card/Card";

import "primeicons/primeicons.css";
import "./Ski.css";

const Ski = (data) => {
  const [isDesktop, setisDesktop] = useState(window.innerWidth > 1000);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setisDesktop(window.innerWidth > 1000);
    });
  }, [isDesktop]);

  useEffect(()=>{
    const handleContextmenu = e => {
      e.preventDefault()
  }
  document.addEventListener('contextmenu', handleContextmenu)
  return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
  }
  },[]);

  return (
    <div className="grid-flex">
      <div className={isDesktop ? "container" : null}>
        <div className={isDesktop ? "" : null}>
          {isDesktop ? (
            <img
              className={isDesktop ? "background-image-top" : null}
              src="/images/background-top.png"
              alt="background top"
            />
          ) : null}
          <div className={isDesktop ? "col" : null}>
            {isDesktop ? (
              <img
                className={isDesktop ? "background-image-left" : null}
                src="/images/background-left.png"
                alt="background ski"
              />
            ) : null}
          </div>
          <div className="row align-items-start">
            <div className={isDesktop ? "col" : null}>
              {isDesktop ? (
                <TreeButton className={isDesktop ? "tree-container" : null} />
              ) : null}
            </div>
            <div className={isDesktop ? "col" : null}>
              <Card
                className={
                  isDesktop ? "card-container-mobile" : "card-container-mobile"
                }
                value={data}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ski;
