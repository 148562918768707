/* eslint-disable import/no-anonymous-default-export */

function getVideoStream(data) {
  if (!data?.key) {
    return null;
  }
 const api = process.env.REACT_APP_API_ENV_VARIABLE;
 
  const url = `${api}api/Video/${data?.key}`;

  return url;
}

export default { getVideoStream };
