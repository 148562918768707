export const Roles = {
    '1': 'admin',
    '2': 'viewer',
}

export const SelectRoles = [{
        name: 'Админ',
        value: 1
    },
    {
        name: 'Ползвател',
        value: 2
    }
]

export const Localize = {
    '1': 'bg',
    '2': 'en',
    'bg': 'bg',
    'en': 'en',
}

export const Languges = [{
        name: 'Български',
        localize: 'bg'
    },
    {
        name: 'English',
        localize: 'en'
    }
]

export const Books = [{
    name: 'Ски учебник',
    code: 'sk',
    session: 2,
    value: 'ski'
}, {
    name: 'Сноуборд учебник',
    code: 'sb',
    session: 3,
    value: 'snowboard'
}, {
    name: 'ски и сноуборд',
    code: 'bt',
    session: 4,
    value: 'ski-snowboard'
}]

export const UserBookAccess = [{
    name: 'спрян',
    value: 1
}, {
    name: 'ски',
    value: 2
}, {
    name: 'сноуборд',
    value: 3
}, {
    name: 'ски и сноуборд',
    value: 4
}]

export const UrlLinks = [{
    name: 'youtu',
    value: 1
}, {
    name: 'drive',
    value: 2
}, {
    name: 'localhost',
    value: 3
}]

export const UserLanguages = [{
        name: 'Български',
        localize: 'bg',
        value: 1
    },
    {
        name: 'English',
        localize: 'en',
        value: 2
    }, {
        name: 'Всички',
        localize: 'ff',
        value: 99
    }
]