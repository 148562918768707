import React, { useState, useEffect, useRef } from "react";
import { Galleria } from "primereact/galleria";

import bookModifierService from "../../services/bookModifier";
//import { PhotoService } from './service/PhotoService';
import { imageGaleriaModel } from "../../common/models";
import "./ImageGaleria.css";

const ImageGaleria = (props) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hideGaleria, setHideGaleria] = useState(true);
  const [isCleared, setIsCleared] = useState(false);
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  // useEffect(() => {
  //     PhotoService.getImages().then(data => setImages(data));
  // }, []);

  useEffect(() => {
    setLoading(true);
    setHideGaleria(images?.length == 0);
    const items = props?.imagesItem ? props?.imagesItem?.split(",") : [];
    let data =
      items
        .filter((f) => f != "")
        .map((i) => ({
          ...imageGaleriaModel,
          itemImageSrc: bookModifierService.getImagePathByName(
            String(i).trim()
          ),
        })) || [];
    setImages(data);
  }, [props]);

  const itemTemplate = (item) => {
    return (
      <img
        src={item?.itemImageSrc}
        alt={item?.alt}
        style={{ width: "100%", display: "block" }}
      />
    );
  };

  // const thumbnailTemplate = (item) => {
  //     return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
  // }

  const geleriaOnLoadHandler = (e) => {
    props.onImageGaleriaLoad(true);
    setHideGaleria(false);
  };

  const galeriaErrorHandler = (e) => {
    setHideGaleria(true);
  };

  const galeriaCaptionHandler = (e) => {
    setTimeout(() => {
      if (e == undefined) {
        //props?.onImageGaleriaFallBack(e);
        setIsCleared(true);
        setTimeout(() => {
          setIsCleared(false);
        }, 100);
      }
    }, 500);
  };

  return (
    <div hidden={hideGaleria} className="image-galeria card">
      {loading && isCleared ? (
        <div></div>
      ) : (
        <Galleria
          value={images}
          responsiveOptions={responsiveOptions}
          numVisible={5}
          // style={{ maxWidth: "640px" }}
          item={itemTemplate}
          // thumbnail={thumbnailTemplate}
          circular
          autoPlay
          transitionInterval={5000}
          caption={galeriaCaptionHandler}
          onError={galeriaErrorHandler}
          onLoad={geleriaOnLoadHandler}
        />
      )}
    </div>
  );
};

export default ImageGaleria;
