import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactPlayer from "react-player";

import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import TimeCounter from "../videoPlayer/TimeCounter";

import { UrlLinks } from "../../common/constants";
import videoRequester from "../../services/videoRequester";
import { youtubeConfig } from "../../common/youtubeConfiguration";

import "./VideoPlayer.css";

const VideoPlayer = (video) => {
  const header = new Headers();
  header.append("Access-Control-Allow-Origin", "test");

  const [loading, setLoading] = useState(true);
  const [hide, setHide] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [end, setEnd] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [urlResponse, setUrlResponce] = useState(null);
  const [key, setKey] = useState(null);
  const [cloudValue, setCloudValue] = useState(3);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [lastPressed, setLastPressed] = useState(0);
  const [timeForBreakSearchUrl, setTimeForBreakSearchUrl] = useState(2);
  const [timeForErrorHandling, setTimeForErrorHandling] = useState(3000);
  //const hasScreenModifyForYoutube = video?.options?.windowInnerWidth && String(urlResponse).includes("youtu");
  const playerRef = useRef(null);

  useEffect(() => {
    setHide(!video?.setHide ?? false);
    setLoading(video?.setHide ?? false);
    setKey(video?.props?.name ? `${video?.props?.name}` : "");
    setUrlResponce(handleUrl(cloudValue));
    setCurrentTime(0);
  }, [video]);

  // useEffect(() => {
  //   setUrlResponce(videoRequester.getVideoStream({ key }));
  // }, [key]);

  useEffect(() => {
    setIsCleared(true);
    setTimeout(() => {
      setIsCleared(false);
    }, 100);
  }, [
    video?.options?.windowInnerWidth && String(urlResponse).includes("youtu"),
  ]);

  const handleTabClick = useCallback(() => {
    const time = new Date().getTime();
    const delta = time - lastPressed;
    setLastPressed(time);
    if (lastPressed) {
      if (delta < 300) {
        // handleFullScreen();
        // setPlaying(!playing);
      } else {
        setPlaying(!playing);
      }
    }
  }, [lastPressed]);

  const switcheCloudValue = () => {
    setTimeout(() => {
      handleUrl(cloudValue + 1);
    }, 3 * 60 * 1000);
  };

  const handleUrl = (cloudValue) => {
    setLoading(true);
    setPlaying(true);
    if (
      !UrlLinks.find((u) => u.value == cloudValue) &&
      timeForBreakSearchUrl > 0
    ) {
      cloudValue = 1;
      setCloudValue(1);
      setTimeForBreakSearchUrl(timeForBreakSearchUrl - 1);
    }
    const cloud = UrlLinks.find((u) => u.value == cloudValue);

    switch (cloud?.value) {
      case 1:
        setCloudValue(1);
        setUrlResponce(video?.props?.videoYoutube);
        return video?.props?.videoYoutube ?? handleUrl(Number(++cloudValue));
      case 2:
        setCloudValue(2);
        setUrlResponce(video?.props?.videoUrl);
        return video?.props?.videoUrl ?? handleUrl(Number(++cloudValue));
      case 3:
        setCloudValue(3);
        setKey(video?.props?.name ? `${video?.props?.name}` : "");
        setUrlResponce(
          videoRequester.getVideoStream({
            key: key ?? video?.props?.name ? `${video?.props?.name}` : "",
          })
        );
        return (
          videoRequester.getVideoStream({
            key: key ?? video?.props?.name ? `${video?.props?.name}` : "",
          }) ?? handleUrl(Number(++cloudValue))
        );
      default:
        return 0;
    }
  };

  const handleFullScreen = () => {
    const elem =
      document.querySelector("video") ?? document.querySelector("iframe");

    if (
      video?.options?.isMobile &&
      !isFullScreen &&
      document.fullscreenEnabled
    ) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen({ navigationUI: "hide" });
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen({ navigationUI: "hide" });
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen({ navigationUI: "hide" });
      }
    } else if (
      video?.options?.isMobile &&
      isFullScreen &&
      document.fullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    } else if (video?.options?.isMobile) {
      //handle error
    }
    setIsFullScreen(!isFullScreen);
  };

  const handleYoutubeWidth = () => {
    return video?.options?.isMobile
      ? video?.options?.windowInnerWidth ?? 560
      : video?.options?.windowInnerWidth * 0.45;
  };

  const handleYoutubeHeight = () => {
    return video?.options?.isMobile
      ? (video?.options?.windowInnerWidth ?? 560) * 0.8
      : video?.options?.windowInnerWidth * 0.45 * 0.8;
  };

  const getYoutubeConfiguration = youtubeConfig({
    width: handleYoutubeWidth(),
    height: handleYoutubeHeight(),
    end,
  });

  const handlePlayerReady = () => {
    setHide(false);
    setCurrentTime(0);
    setPlaying(true);
    return true;
  };

  const handlePlayerError = () => {
    setTimeForErrorHandling(3000);
    const nextCloudValue = cloudValue + 1;
    setCurrentTime(0);
    setDuration(0);
    setPlaying(false);
    setHide(false);
    setLoading(true);
    handleUrl(nextCloudValue);
    setTimeout(() => {
      setLoading(false);
      setHide(true);
    }, timeForErrorHandling);
  };

  const handlePlayerSeekTimer = (seekTime, delay = 500, isPlaying = true) => {
    setTimeout(() => {
      setCurrentTime(seekTime);
      if (seekTime >= 0) {
        playerRef.current?.seekTo(seekTime, "seconds");
        setPlaying(isPlaying);
      }
    }, delay);
  };

  const image = () => {
    return (
      <>
        <img
          className="image-missing-video-or-error"
          src="/images/main-pic.jpg"
          alt="missing-video-or-error"
        />
        <br />
        <i>'Липсващо или недостъпно видео'</i>
        <br />
        <i>'Video missing or error'</i>
      </>
    );
  };

  const CustomProgressBar = () => {
    return (
      <div
        className="progress-bar-custom"
        onClick={(event) => {
          const x =
            (event.clientX * duration) / event.currentTarget.offsetWidth;
          const position = video?.options?.isMobile ? x : x - duration * 0.67;
          setCurrentTime(position);
          playerRef.current.seekTo(position, "seconds");
        }}
        style={{
          backgroundColor: "#ddd",
          borderRadius: 3,
          flex: 1,
          height: 6,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <ProgressBar
          className="progress-bar"
          value={(currentTime / duration) * 100}
          unit=""
        />
      </div>
    );
  };

  return (
    <div
      // onMouseOver={(e) => {
      //   console.log("mouse over player");
      // }}
      className="player-wrapper"
    >
      <div className="card" hidden={!hide || duration}>
        {image()}
      </div>

      {loading && <Skeleton className="skeleton-video" />}
      <div
        // onDoubleClick={handleFullScreen}
        onClick={(e) => {
          if (!video?.options?.isMobile) {
            setPlaying(!playing);
          } else {
            handleTabClick();
          }
        }}
        className="player"
      >
        {isCleared ? (
          <div></div>
        ) : (
          <ReactPlayer
            ref={playerRef}
            muted={isMuted}
            onDuration={(e) => {
              setDuration(e);
            }}
            onProgress={(e) => {
              setCurrentTime(e?.playedSeconds);
              if (e?.playedSeconds > 0) {
                setHide(false);
                setTimeForErrorHandling(12 * 60 * 60 * 1000);
                setLoading(false);
                setTimeForBreakSearchUrl(2);
                setIsMuted(false);
              }
            }}
            style={{
              display: (!loading && !hide) ? "block" : "none",
              pointerEvents: "none",
            }}
            // Disable download button
            stopOnUnmount={true}
            config={{
              file: { attributes: { controlsList: "nodownload" } },
              youtube: getYoutubeConfiguration,
            }}
            // Disable right click
            onContextMenu={(e) => e.preventDefault()}
            autoPlay
            playing={playing}
            loop
            url={urlResponse == "" ? "none" : urlResponse}
            width={
              String(urlResponse).includes("drive") ||
              String(urlResponse).includes("localhost") ||
              String(urlResponse).includes("uidevices")
                ? "100%"
                : video?.props?.width || "100%"
            }
            height={
              String(urlResponse).includes("drive") ||
              String(urlResponse).includes("localhost") ||
              String(urlResponse).includes("uidevices")
                ? "100%"
                : video?.props?.height || "100%"
            }
            controls={false}
            onReady={handlePlayerReady}
            onPause={() => setPlaying(false)}
            onSeek={() => currentTime}
            // onStart={() => {
            //   switcheCloudValue();
            // }}
            onError={handlePlayerError}
          />
        )}
        {CustomProgressBar()}
      </div>
      <div className="player-button flex">
        <Button
          visible={!playing}
          className="mr-2"
          onClickCapture={() => {
            setPlaying(true);
            setIsMuted(false);
            setEnd(false);
          }}
          icon="pi pi-play"
          outlined
        />

        <Button
          visible={playing}
          className="mr-2"
          onClickCapture={() => {
            setIsMuted(true);
            setPlaying(false);
          }}
          icon="pi pi-pause"
          outlined
        />
        <Button
          className="mr-8"
          onClickCapture={() => {
            setIsMuted(true);
            handlePlayerSeekTimer(0, 100, false);
            setTimeout(() => {
              setPlaying(false);
            }, 500);
          }}
          icon="pi pi-stop"
          outlined
        />
        <Button
          visible={false}
          className="mr-2"
          onClickCapture={() => {
            setIsMuted(true);
            setPlaying(false);
            const backwardTime = currentTime - 1 < 0 ? 0 : currentTime - 1;
            setTimeout(() => {
              handlePlayerSeekTimer(backwardTime);
            }, 1000);
          }}
          icon="pi pi-backward"
          outlined
        />
        <Button
          visible={false}
          className="mr-8"
          onClickCapture={() => {
            setIsMuted(true);
            setPlaying(false);
            const forwardTime =
              currentTime + 1 > duration ? duration : currentTime + 1;
            setTimeout(() => {
              handlePlayerSeekTimer(forwardTime);
            }, 1000);
          }}
          icon="pi pi-forward"
          outlined
        />
        <TimeCounter
          video={{ ...video, currentTime, duration }}
          // styles={this.props.styles}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
