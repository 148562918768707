/* eslint-disable import/no-anonymous-default-export */
import { initializeApp } from "firebase/app";
import {
  getAuth,
  deleteUser,
  updatePassword,
  // confirmPasswordReset,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  // updateCurrentUser,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  //updateDoc,
  deleteDoc,
} from "firebase/firestore";
// import {
//     useRef,
//     useState
// } from "react";

import userSessionStorage from "./userSessionStorage";
import { decrypt, wordGenerator, crypt } from "../common/helpers";

const env = process.env;

const firebaseConfig = {
  apiKey: env.REACT_APP_API_KEY,
  authDomain: env.REACT_APP_AUTH_DOMAIN,
  projectId: env.REACT_APP_PROJECT_ID,
  storageBucket: env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_APP_ID,
  measurementId: env.REACT_APP_MEASERMENT_ID,
};

const app = initializeApp(firebaseConfig);
const dbFirebase = getFirestore(app);

// let userData = null;

const auth = getAuth();

async function verifyUseUidToken() {
  const idToken = userSessionStorage.getToken();
  auth
    .verifyIdToken(idToken)
    .then((decodedToken) => {
      // const uid = decodedToken.uid;
      // console.log(decodedToken);
      // console.log(uid);
      // ...
    })
    .catch((error) => {
      // Handle error
    });
  if (idToken) {
    // Verify the ID token first.
    // auth.verifyIdToken(idToken)
    //   .then((claims) => {
    //     console.log(claims)
    //     // if (claims.admin === true) {
    //     //   // Allow access to requested admin resource.
    //     // }
    //   });
    // await auth.currentUser
    //   .getIdToken(idToken, true)
    //   .then(function (idToken) {
    //     // Send token to your backend via HTTPS
    //     userSessionStorage.setToken(idToken);
    //     return true;
    //     // ...
    //   })
    //   .catch(function (error) {
    //     // Handle error
    //     console.log(error)
    //   });
  }
}

function getCurrentUser() {
  const user = auth.currentUser.toJSON();
  const refreshToken = user?.stsTokenManager?.refreshToken;
  const expirationTime = user?.stsTokenManager?.expirationTime;
  //accessToken
  return {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    refreshToken: refreshToken,
    expirationTime: expirationTime,
  };
}

async function getAuthUser(data) {
  return signInWithEmailAndPassword(auth, data?.email, data?.password);
}

async function getUser(data) {
  const q = query(
    collection(dbFirebase, "users"),
    where("email", "==", data?.email)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

async function getDataFromDbByDocId(data) {
  const docRef = doc(dbFirebase, data.collectionGroup, data.document);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    //console.log("Document data:", docSnap.data());
    return docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    // console.log("No such document!");
    return null;
  }
}

async function getRoles() {
  const q = query(collection(dbFirebase, "roles"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    //console.log(doc.id, " => ", doc.data());
    //console.log(doc.data())
    return doc.data();
  });
}

async function getUsers(data) {
  const q = query(collection(dbFirebase, "users"));

  const querySnapshot = await getDocs(q);
  return querySnapshot;
  // querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     //console.log(doc.id, " => ", doc.data());
  //     // console.log(doc.data())
  //     // return doc.data();
  // });
}

async function userResetPassword(data) {
  const email = data?.email;
  const result = sendPasswordResetEmail(auth, email);
  return result;
}

async function getDataFromDb(data) {
  const q = query(collection(dbFirebase, data?.collectionGroup));

  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

async function setUser(data) {
  let result = {
    authSuccess: null,
    authError: null,
    userSuccess: null,
    userError: null,
  };
  let password = decrypt(data.hash);
  const hasNoPassword = !password;

  if (data.hasNewUser && !password) {
    const generatedPassword = wordGenerator(12);
    password = crypt(generatedPassword);
    data.hash = password;
  }

  if (data.hasNewUser && password) {
    const decryptPassword = decrypt(password);
    createUserWithEmailAndPassword(auth, data.email, decryptPassword)
      .then((userCredential) => {
        // Signed up
        // const user = userCredential.user;
        // ...
        result = { ...result, authSuccess: "success" };
        if (hasNoPassword) {
          userResetPassword(data)
            .then(() => {})
            .catch(() => {});
        }
      })
      .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        result = { ...result, authError: error.message };
        //return error.message;
        // ..
      });
  }

  if (data.hasPasswordToUpdate) {
    let userToUpdate = null;
    const password = decrypt(data.hash);
    const result = getAuthUser({
      ...data,
      password,
    })
      .then((userCredential) => {
        userToUpdate = userCredential?.user;
        const chagedPassword = decrypt(data.hasPasswordToUpdate);
        updatePassword(userToUpdate, chagedPassword)
          .then(() => {})
          .catch((error) => {});
      })
      .catch((res) => {
        //handle error
      });
  }

  await setDoc(
    doc(dbFirebase, "users", String(data.id)),
    {
      ...data,
      hash: data.hasPasswordToUpdate ? data.hasPasswordToUpdate : data.hash,
      hasNewUser: false,
      hasPasswordToUpdate: false,
    },
    {
      merge: true,
    }
  )
    .then((res) => {
      result = { ...result, userSuccess: "success" };
    })
    .catch((res) => {
      //console.log(res);
      result = { ...result, userError: "error" };
      return res;
    });
  return result;
}

async function setDataToDb(data) {
  await setDoc(
    doc(dbFirebase, data?.collectionGroup, String(data.id)),
    {
      ...data,
    },
    {
      merge: true,
    }
  ).catch((res) => {
    // console.log(res);
    return res;
  });
}

async function deleteUserFromDb(data) {
  let userToDelete = null;
  let success = null;
  const password = decrypt(data.hash);
  const result = getAuthUser({ ...data, password })
    .then((userCredential) => {
      userToDelete = userCredential?.user;
      return deleteAuthUser(userToDelete)
        .then((res) => {
          success = true;
          return success;
        })
        .catch((res) => {
          //handle error
          success = false;
          return success;
        });
    })
    .catch((res) => {
      //handle error
      return false;
    });
    
  await deleteDoc(doc(dbFirebase, "users", String(data.id)))
    .then((res) => {
      return res;
    })
    .catch(() => {});
}

async function deleteAuthUser(userData) {
  return deleteUser(userData);
}

async function deleteDataFromDbByDocId(data) {
  await deleteDoc(doc(dbFirebase, data?.collectionGroup, String(data.id))).then(
    (res) => {
      return res;
    }
  );
}

async function getNodesData() {
  let nodesData = [];
  const q = query(collection(dbFirebase, "nodes"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (sessionStorage.getItem("localize") == "ff") {
      let data = doc.data();
      nodesData.push(data);
    } else if (
      doc.data()?.localize == sessionStorage.getItem("localize") ??
      "bg"
    ) {
      let data = doc.data();
      nodesData.push(data);
    }
  });
  return nodesData;
}

async function getDataByCollection(name) {
  let collectionData = [];
  const q = query(collection(dbFirebase, name));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let data = doc.data();
    collectionData.push(data);
  });
  return collectionData;
}

const nodes = await getNodesData();

async function setNode(data) {
  await setDoc(
    doc(dbFirebase, "nodes", String(data.id)),
    {
      ...data,
    },
    {
      merge: true,
    }
  ).catch((res) => {
    //console.log(res);
    return res;
  });
}

async function deleteNodeById(data) {
  await deleteDoc(doc(dbFirebase, "nodes", String(data.id))).then((res) => {
    return res;
  });
}

async function signOutUser() {
  signOut(auth);
}

export default {
  getAuthUser,
  verifyUseUidToken,
  getCurrentUser,
  userResetPassword,
  getDataFromDbByDocId,
  getDataFromDb,
  getUsers,
  getRoles,
  getUser,
  getNodesData,
  getDataByCollection,
  setUser,
  setDataToDb,
  setNode,
  deleteUserFromDb,
  deleteNodeById,
  nodes,
  signOutUser,
  deleteDataFromDbByDocId,
};
