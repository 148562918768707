import React from 'react';

const AppFooter = (props) => {
    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                {/* <img id="footer-logo" src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} alt="atlantis-layout" /> */}
                <span className="app-name">BOOK SKI & SNOWBOARD</span>
            </div>
            <span className="copyright">&#169; Bg Ski School 2023</span>
        </div>
    );
};

export default AppFooter;
